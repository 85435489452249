import PropTypes from "prop-types"
import React from "react"
import Box from "./box"

const EditableContent = ({
  initialContent,
  contentChanged,
  fontSize,
  p,
  fontFamily,
  ...restProps
}) => {
  const innerContentChanged = (e) => {
    if (contentChanged) {
      contentChanged({
        htmlContent: e.currentTarget.innerHTML,
        textContent: e.currentTarget.textContent,
      })
    }
  }

  return (
    <Box
      contentEditable
      suppressContentEditableWarning
      onInput={innerContentChanged}
      onBlur={innerContentChanged}
      sx={{
        appearance: "none",
        display: "block",
        width: "100%",
        fontSize: fontSize,
        fontFamily: fontFamily,
        lineHeight: "inherit",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "transparent",
        borderRadius: "md",
        color: "inherit",
        bg: "transparent",
        p: p,
        variant: "forms.textarea",
      }}
      {...restProps}
    >
      {initialContent}
    </Box>
  )
}

EditableContent.propTypes = {
  initialContent: PropTypes.string,
  contentChanged: PropTypes.func,
  fontSize: PropTypes.string,
  fontFamily: PropTypes.string,
}

EditableContent.defaultProps = {
  initialContent: "",
  p: 2,
  fontSize: "inherit",
  fontFamily: "sans",
}

export default EditableContent
