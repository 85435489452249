import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import EditableContent from "../../../../../components/editable-content"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import PaperStack from "../../../../../components/paper-stack"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })
  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/satzbau/loesung" />
      }
      navigation={navigation}
    >
      <Seo title="Satzenden finden" />
      <Stack>
        <Heading as="h2" level={2}>
          Satzenden finden
        </Heading>
        <Paragraph>
          In seine Sätze hat Hölderlin häufig Wendungen und Einschübe eingebaut
          und damit die Regeln für Satzbau und Grammatik bis aufs Äußerste
          strapaziert. Wo ein Satz endet und wo der nächste beginnt, ist dabei
          oftmals gar nicht klar.
        </Paragraph>
        <TaskSidebar
          main={
            <PaperStack p={[3, 4]}>
              <EditableContent
                fontSize={[3, 3, 4]}
                p={[3, 4]}
                fontFamily="serif"
                contentChanged={({ htmlContent }) => {
                  setAnswer({
                    courseId: "haelfte-des-lebens",
                    chapterId: "01-zwei-haelften",
                    taskId: "satzbau",
                    answer: htmlContent,
                  })
                }}
                initialContent="Mit gelben Birnen hänget und voll mit wilden Rosen das Land in den See, ihr holden Schwäne, und trunken von Küssen tunkt ihr das Haupt ins heilignüchterne Wasser. Weh mir, wo nehm’ ich, wenn es Winter ist, die Blumen, und wo den Sonnenschein, und Schatten der Erde? Die Mauern stehn sprachlos und kalt, im Winde klirren die Fahnen."
              />
            </PaperStack>
          }
          sidebar={
            <Note variant="task">
              Aus wievielen Sätzen besteht ›Hälfte des Lebens‹? Unterteile das
              Gedicht in seine einzelnen Sätze, indem du nach jedem Satz einen
              doppelten Textumbruch ↩↩ einfügst.
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
